//(function (root, factory) {
//  if (typeof define === 'function' && define.amd) {
//    // AMD. Register as an anonymous module.
//    define([
//      'chartist',
//      'jquery'
//    ], function (chartist, jquery) {
//      return root.returnExportsGlobal = factory(chartist, jquery)
//    })
//  } else if (typeof exports === 'object') {
//    // Node. Does not work with strict CommonJS, but
//    // only CommonJS-like enviroments that support module.exports,
//    // like Node.
//    module.exports = factory(require('chartist'), require('jquery'))
//  } else {
//    root['Chartist.plugins.legend'] = factory(root.chartist, root.jquery)
//  }
//}(this, function () {
/**
 * This Chartist plugin creates a legend to show next to the chart.
 * 
 */
(function (window, document, Chartist) {
  'use strict';
  var defaultOptions = {
    className: '',
    imageName: '',
    elementId: function () {
      return 'ftb-download-button-' + +new Date()
    }
  };
  Chartist.plugins = Chartist.plugins || {};
  Chartist.plugins.ctDownloadLink = function (options) {
    options = Chartist.extend({}, defaultOptions, options);
    return function ctDownloadLink(chart) {
      var elementId = typeof options.elementId === 'function' ? options.elementId() : options.elementId;
      chart.on('created', function (data) {
        var chartElement = data.svg._node.parentNode;
        var containerElement = chartElement.parentNode;
        var previousElement = containerElement.querySelector('#' + elementId);
        if (previousElement) {
          containerElement.removeChild(previousElement)
        }
        /*
         * append computed styles to elements
         */
        function generateSVG(id) {
          // hardcode computed css styles inside svg
          var svgDoc = document.getElementById(id).firstChild;
          var allElements = traverse(svgDoc);
          var svgDocCloned = document.getElementById(id).firstChild.cloneNode(true);
          var allElementsCloned = traverse(svgDocCloned);
          var i = allElements.length;
          while ((i -= 1) > 0) {
            explicitlySetStyle(allElements[i], i, allElementsCloned)
          }
          var svgData = new XMLSerializer().serializeToString(svgDocCloned);
          svgData = '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">' + svgData;
          var svgDataAsBlob = new Blob([svgData], { type: 'image/svg+xml' });
          if (navigator.appVersion.toString().indexOf('.NET') > 0) {
            window.navigator.msSaveBlob(svgDataAsBlob, options.imageName + '.svg')
          } else {
            var a = document.createElement('a');
            if (window.webkitURL != null) {
              a.href = window.webkitURL.createObjectURL(svgDataAsBlob)
            } else {
              a.href = window.URL.createObjectURL(svgDataAsBlob)
            }
            a.download = options.imageName + '.svg';
            a.innerHTML = 'Download SVG';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a)
          }
        }
        var downloadButton = document.createElement('button');
        downloadButton.setAttribute('id', elementId);
        downloadButton.setAttribute('class', options.className);
        downloadButton.innerHTML = 'Download SVG';
        downloadButton.onclick = function () {
          generateSVG(chartElement.id)
        };
        downloadButton.onkeypress = function () {
          generateSVG(chartElement.id)
        };
        containerElement.appendChild(downloadButton);
        function explicitlySetStyle(element, index, allElementsCloned) {
          var cSSStyleDeclarationComputed = getComputedStyle(element);
          var i, len, key, value;
          var computedStyleStr = '';
          var stylestoExport = [
            'fill',
            'font-family',
            'font-size',
            'height',
            'line-height',
            'width',
            'display',
            'color',
            '-webkit-align-items',
            'align-items',
            'stroke',
            'stroke-width',
            'stroke-dasharray',
            'text-anchor'
          ];
          for (i = 0, len = cSSStyleDeclarationComputed.length; i < len; i += 1) {
            key = cSSStyleDeclarationComputed[i];
            value = cSSStyleDeclarationComputed.getPropertyValue(key);
            if (stylestoExport.indexOf(key) !== -1) {
              computedStyleStr += key + ':' + value + ';'
            }
          }
          allElementsCloned[index].setAttribute('style', computedStyleStr)
        }
        function traverse(obj) {
          var tree = [];
          tree.push(obj);
          if (obj.hasChildNodes()) {
            var child = obj.firstChild;
            while (child.nodeName != 'svg') {
              if (child.nodeType === 1 && child.nodeName != 'SCRIPT') {
                tree.push(child)
              }
              if (child.hasChildNodes()) {
                child = child.firstChild
              } else if (child.nextSibling) {
                child = child.nextSibling
              } else {
                while (child.nodeName != 'svg') {
                  if (child.parentNode.nextSibling) {
                    child = child.parentNode.nextSibling;
                    break
                  } else {
                    child = child.parentNode
                  }
                }
              }
            }
          }
          return tree
        }
      })
    }
  }
}(window, document, Chartist))